import { render, staticRenderFns } from "./CandidateRegistration.vue?vue&type=template&id=c92dc9b2&scoped=true&"
import script from "./CandidateRegistration.vue?vue&type=script&lang=js&"
export * from "./CandidateRegistration.vue?vue&type=script&lang=js&"
import style0 from "./CandidateRegistration.vue?vue&type=style&index=0&id=c92dc9b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c92dc9b2",
  null
  
)

export default component.exports